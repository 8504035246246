<template>
  <div id="main-wrapper" class="inner-pages depbonus-page loyal-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>Loyalty Program</h2>
        <p>The more you trade, the more rewards you earn automatically!</p>
      </div>
    </div>
    <div class="section bonus-trade">
      <div class="wrapper">
        <div class="title-holder wow fadeIn">
          <h5>
            They say loyalty goes both ways. We earn our clients' loyalty
            through our hard work <br />and transparency, and we believe in
            returning it through our free Rewards program.
          </h5>
        </div>
        <br /><br />
        <div class="title-holder t-left wow fadeIn">
          <h3>What is AAAFx loyalty program?</h3>
          <br />
          <p>
            Every trade counts in a trader's journey. Each trade you place with
            us goes a long way in giving you exciting returns through our
            marvellous Loyalty Program. <br /><br />
            There is no need to sign up. All of your trades will keep earning
            your Reward Points (RPs) automatically. Upon gathering certain
            number of Reward Points (RPs), you can redeem them in several
            different ways, including cash!
          </p>
          <div class="listing t-center">
            <div class="prog-holder col wow fadeIn">
              <p>Deposit</p>
              <img src="assets/images/loyal-ico1.webp" alt="AAAFx" title="AAAFx" width="71" height="72" class="fluid-img" />
            </div>
            <div class="prog-holder col wow fadeIn" data-wow-delay="0.3s">
              <p>Trade</p>
              <img src="assets/images/loyal-ico2.webp" alt="AAAFx" title="AAAFx" width="71" height="72" class="fluid-img" />
            </div>
            <div class="prog-holder col wow fadeIn" data-wow-delay="0.6s">
              <p>Earn RPs</p>
              <img src="assets/images/loyal-ico3.webp" alt="AAAFx" title="AAAFx" width="71" height="72" class="fluid-img" />
            </div>
            <div class="prog-holder col wow fadeIn" data-wow-delay="0.9s">
              <p>Redeem RPs</p>
              <img src="assets/images/loyal-ico4.webp" alt="AAAFx" title="AAAFx" width="71" height="72" class="fluid-img" />
            </div>
          </div>
        </div>
        <div class="section-small">
          <div class="wrapper">
            <div class="title-holder wow fadeIn">
              <h5>
                Our loyalty program is suitable for all kinds of<br />
                traders. There are two ways of progressing<br />
                through its Tier Levels:
              </h5>
            </div>
            <div class="listing t-center routes">
              <div class="prog-holder col wow fadeInLeft">
                <h3>Express Route</h3>
                <p>
                  Suitable for those traders who like to trade more in one go
                </p>
              </div>
              <div class="prog-holder col wow fadeInRight">
                <h3>Steady Route</h3>
                <p>
                  For those who like to trade consistently over a longer period
                  of time
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section fab-sec">
      <div class="wrapper2">
        <div class="title-holder wow fadeIn">
          <h3>Our Fabulous Four Loyalty Tiers</h3>
          <br />
          <h6>Trade More, Earn More</h6>
          <br />
          <h6>
            Its keeps getting bigger and better with every tier you climb up.
          </h6>
          <br />
          <p>
            We have two very simple and flexible routes to qualify for the
            loyalty tiers. Whichever route you take, we will make sure that
            <br />you earn the same applicable Loyalty tiers and the same reward
            points. If you overlap in these two routes, you will be
            <br />allotted the higher applicable tier out of the two routes.
          </p>
        </div>
        <div class="img-holder t-center wow fadeIn">
          <img src="assets/images/loyal-img3.webp" alt="AAAFx" title="AAAFx" width="1368" height="743" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section redeem-sec">
      <div class="wrapper">
        <div class="title-holder">
          <h3>Redeem your Reward Points</h3>
          <br />
          <p>Earn More, Redeem More</p>
          <br />
          <p>
            Flexible and attractive options to convert your Reward points into
            numerous benefits
          </p>
        </div>
        <div class="listing redeem-list t-left">
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>Cash (Min. 500 RPs can be redeemed for cash)</p>
              <h3><span>10</span>RPs = 1USD</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal1.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>Free Basic VPS for 3 months</p>
              <h3><span>600</span> RPs</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal2.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>Free Premium VPS for 3 months</p>
              <h3><span>1000</span>RPs</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal3.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>SeatsonAX Subscription for 3 months</p>
              <h3><span>2000</span>RPs</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal4.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>Even Faster Withdrawals for the next 20 days</p>
              <h3><span>2500</span>RPs</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal5.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>Move to the Next Reward Tier level</p>
              <h3><span>3000</span>RPs</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal6.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>
                Iphone/Ipad or a similar smart device of up to $1200 in value
              </p>
              <h3><span>10,000</span>RPs</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal7.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>1 Pip flat spread for 10 trading days</p>
              <h3><span>25,000</span>RPs</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal8.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>10% extra IB commission for last month</p>
              <h3><span>7*</span> Additional Comm. Amount</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal9.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>$100 extra for each referral in last month</p>
              <h3><span>700</span>RPs per referral</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal10.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>1 month commission reimbursement</p>
              <h3><span>7*</span>Reimbursement amount</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal11.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>Advance deposit for deposit in transit</p>
              <h3><span>2*</span>Advance Deposit Amount</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal12.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>Additional bonus in account</p>
              <h3><span>2*</span>Bonus Amount</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal13.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>1 Month Swap Fee Reimbursement</p>
              <h3><span>7*</span>Reimbursement Amount</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal14.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>10% Extra (withdrawable) deposit on the last 5 Deposits</p>
              <h3><span>6*</span>Reimbursement Amount</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal15.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>Loss Reimbursement on any one trade in last 20 trading days</p>
              <h3><span>7*</span> Reimbursement Amount</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal16.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>
                Spread reimbursement on 10 loss making trades (in last 20 days)
              </p>
              <h3><span>7*</span>Reimbursement Amount</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal17.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
        </div>
        <div class="title-holder t-left">
          <p>
            Read terms and conditions of our Loyalty Program
            <a href="#"> here</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
